import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { ProductModel } from '@/domain/product.model';

export class ProductsRepository extends AxiosFactory {
  constructor() {
    super('/products');
  }

  async findProductBySlug(product_slug: ProductModel['product_slug']): Promise<CustomAxiosResponse<ProductModel>> {
    return await this.axiosApi.get('/' + product_slug);
  }
}
