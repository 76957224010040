import { defineStore } from 'pinia';
import type { ProductModel } from '../domain/product.model';

export type ObjectTipoDeInformeSelect = {
  id: ProductModel['product_slug'];
  text: string;
};

export const useRequestStore = defineStore('requestStore', {
  state: () => {
    return {
      matricula: '',
      express: false,
      userEmail: '',
      formTerms: false,
      typesInforme: [
        {
          id: 'informe-completo',
          text: 'Informe Completo'
        },
        {
          id: 'informe-basico',
          text: 'Informe Basico'
        }
      ],
      typesDataMatricula: [
        {
          id: 'matricula',
          text: 'Matrícula'
        },
        {
          id: 'bastidor',
          text: 'Número de Bastidor'
        }
      ],
      formTypeMatricula: {
        id: 'matricula',
        text: 'Matrícula'
      },
      formTypeInforme: {
        id: 'informe-completo',
        text: 'Informe Completo'
      }
    };
  },
  actions: {
    setFormTypeInforme(type: ProductModel['product_slug']) {
      const typeInfome = this.typesInforme.find(t => t.id == type);
      this.formTypeInforme = typeInfome as ObjectTipoDeInformeSelect;
    }
  }
});
