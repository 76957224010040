import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { ResponsePayRequest } from './requests.response';
import { PaymentModel } from '@/domain/payment.model';

export interface PayRequestDto {
  user_email: string;
  product_rel_id: string;
  request_matricula: string;
  request_fast: boolean;
  request_type_matricula: string;
  payment_provider_token: string;
}

export class RequestsRepository extends AxiosFactory {
  constructor() {
    super('/payments');
  }

  async payRequest(form: PayRequestDto): Promise<CustomAxiosResponse<ResponsePayRequest>> {
    return await this.axiosApi.post('/', form);
  }
  async verificationRequest(payment_id: string): Promise<CustomAxiosResponse<PaymentModel>> {
    return await this.axiosApi.put('/' + payment_id);
  }
}
