import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { trackRouter } from 'vue-gtag-next';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/informe',
    name: 'InformeView',
    component: () => import(/* webpackChunkName: "informe" */ '../views/InformeView.vue')
  },
  {
    path: '/informe',
    component: () => import(/* webpackChunkName: "solicitud" */ '../views/RequestView.vue'),
    children: [
      {
        path: 'solicitud',
        name: 'InformeRequestView',
        component: () => import(/* webpackChunkName: "informeFormulario"*/ '@/components/RequestForm.vue')
      },
      {
        path: 'pago',
        name: 'InformeRequestPayment',
        component: () => import(/* webpackChunkName: "informePago"*/ '@/components/RequestPayment.vue')
      },
      {
        path: 'termiado',
        name: 'InformeRequestSuccess',
        component: () => import(/* webpackChunkName: "informeSuccess"*/ '@/components/RequestSuccess.vue')
      }
    ]
  },
  {
    path: '/terminos',
    name: 'PageTerms',
    component: () => import(/* webpackChunkName: "terminos" */ '../views/pageTermsView.vue')
  },
  {
    path: '/privacy',
    name: 'PagePrivacy',
    component: () => import(/* webpackChunkName: "privacidad" */ '../views/pagePrivacyView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (to.hash) {
          resolve({
            el: to.hash,
            behavior: 'smooth'
          });
        } else {
          resolve({
            top: 0,
            behavior: 'smooth'
          });
        }
      }, 1);
    });
  }
});

trackRouter(router);

export default router;
